import React from "react";
import Login from "./Login/Login";

function App() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default App;

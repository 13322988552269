import React, { FormEvent, useState } from "react";
import { Button, Container, Form, Message, Card } from "semantic-ui-react";
import { Logo } from "../Logo/Logo";
import "./Login.css";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    setError(false);
    if (username !== "" && password !== "") {
      setUsernameError(false);
      setPasswordError(false);
      authorize(username, password);
    } else {
      if (username === "") {
        setUsernameError(true);
      }
      if (password === "") {
        setPasswordError(true);
      }
    }
  };

  const getServiceUrl = () => {
    if (window.location.host.includes("localhost")) {
      return "http://localhost:8080";
    } else {
      return "https://login-landing.baltocloud.com";
    }
  };

  const authorize = (username: string, password: string) => {
    setLoading(true);
    fetch(`${getServiceUrl()}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password,
      }),
    })
      .then((response: Response) => {
        if (response.ok) {
          setSuccess(true);
          getRedirectUrl(response).then(
            (href) => (window.location.href = href)
          );
        } else {
          setError(true);
        }

        setLoading(false);
      })
      .catch((reason) => {
        console.log("Login failed with: ", reason);
        setError(true);
        setLoading(false);
      });
  };

  function handleSetUsername(e: React.ChangeEvent<HTMLInputElement>) {
    setUsernameError(false);
    setUsername(e.target.value);
  }

  function handleSetPassword(e: React.ChangeEvent<HTMLInputElement>) {
    setPasswordError(false);
    setPassword(e.target.value);
  }

  return (
    <div className="vertical-wrapper">
      <Container className="auth-container">
        <Logo height={"80"} />
        <h2>Login to Balto's Documentation Hub</h2>
        <Form
          onSubmit={handleSubmit}
          loading={loading}
          error={error}
          success={success}
        >
          <Form.Field required>
            <label>Email</label>
            <Form.Input
              error={usernameError}
              type="text"
              name="username"
              onChange={(e) => handleSetUsername(e)}
            />
          </Form.Field>
          <Form.Field required>
            <label>Password</label>
            <Form.Input
              error={passwordError}
              type="password"
              name="password"
              onChange={(e) => handleSetPassword(e)}
            />
          </Form.Field>
          <Button fluid primary>
            Login
          </Button>
          <Message error content="Incorrect username or password" />
          <Message
            success
            content="Authentication Successful, please wait while you are redirected..."
          />
          <Card>
            <Card.Content>
              <p>
                This is the same username and password used to login to{" "}
                <a
                  href="https://baltocloud.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  baltocloud.com
                </a>
              </p>

              <p>
                <a
                  href="https://baltocloud.com/reset_password"
                  target="_blank"
                  rel="noreferrer"
                >
                  Forgot your password?
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:integrations@balto.ai?subject=Balto%20Doc%20Hub%20Access&body=Include%20your%20name%20company%20and%20reason%20for%20access%20here"
                >
                  Request Doc Hub Access
                </a>
              </p>
            </Card.Content>
          </Card>
        </Form>
      </Container>
    </div>
  );
}

const getRedirectUrl = async (response: Response) =>
  await response.json().then((body) => body?.redirectUrl);

export default Login;
